import React, { Component } from 'react';

import './wz.scss';

class PrintGera extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }



    render() {
        return(
                <div className="wyrob ">
               <div className="print_order">{this.props.data[0].name}</div> 
                </div>
            )


}
}
export default PrintGera;