import React, { Component } from 'react';

import './wz.scss';

class PrintWG extends Component {
    constructor(props){
        super(props);
        this.state = {
            qtyPack:'',

        }
    }

countChange = (e) =>{
    this.setState({qtyPack:  e.target.value})
}


    render() {


        return (
            <div className="wyrob">
                <h2>WYRÓB GOTOWY</h2>
               <div className="wyrob-body">
                   <div className="wyrob-body_title">
                       <h4>NR ZLECENIA</h4>
                       <textarea className="input-title" defaultValue={this.props.data[0].name}></textarea>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>TYTUŁ ZLECENIA</h4>
                       <textarea className="input-title name" defaultValue={this.props.data[0].title} ></textarea>
                   </div>
                   <div className="wyrob-body_item">
                        <div className="item">
                           <h4>ILOŚĆ NA PALECIE:</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]}></textarea>
                        </div>
                        <div className="item">
                           <h4>ILOŚĆ PACZEK:</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]/ this.state.qtyPack}></textarea>
                        </div>
                        <div className="item">
                           <h4>ILOŚĆ SZTUK W PACZCE:  </h4>
                           <textarea className="input-item" value={this.state.qtyPack} onChange={this.countChange} ></textarea>
                        </div>
                        <div className="item">
                           <h4>NR KOLEJNY PALETY: </h4>
                           <textarea className="input-item" defaultValue='1'></textarea>
                        </div>
                        <div className="item last-item">
                           <h4>ILOŚĆ PALET W ZLEC.: </h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].subcount} ></textarea>
                        </div>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>UWAGI:</h4>
                       <textarea className="input-title"></textarea>
                   </div>
               </div>
            </div>
        );
    }
}

export default PrintWG;