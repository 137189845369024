import React, { Component } from 'react';

import './wz.scss';

class PrintEN extends Component {
    constructor(props){
        super(props);
        this.state = {
            qtyPack:''
        }
    }

countChange = (e) =>{
    this.setState({qtyPack:  e.target.value})
}


    render() {


        return (
            <div className="wyrob">
                <h2>FINAL PRODUCT</h2>
               <div className="wyrob-body">
                   <div className="wyrob-body_title">
                       <h4>ORDER NUMBER:</h4>
                       <textarea className="input-title" defaultValue={this.props.data[0].name}></textarea>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>ORDER TITLE:</h4>
                       <textarea className="input-title name" defaultValue={this.props.data[0].title} ></textarea>
                   </div>
                   <div className="wyrob-body_item">
                        <div className="item">
                           <h4>QUANTITY ON THE PALETTE:</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]} ></textarea>
                        </div>
                        <div className="item">
                           <h4>NUMBER OF PACKAGES:</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]/ this.state.qtyPack}></textarea>
                        </div>
                        <div className="item">
                           <h4>QUANTITY OF PIECES IN THE PACKAGE: </h4>
                           <textarea className="input-item" value={this.state.qtyPack} onChange={this.countChange}  ></textarea>
                        </div>
                        <div className="item">
                           <h4>NEXT PALLET NUMBER: </h4>
                           <textarea className="input-item"></textarea>
                        </div>
                        <div className="item last-item">
                           <h4>TOTAL PALLETS NUMBER: </h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].subcount} ></textarea>
                        </div>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>COMMENTS:</h4>
                       <textarea className="input-title"></textarea>
                   </div>
               </div>
            </div>
        );
    }
}

export default PrintEN;