import React, { Component } from 'react';

import './wz.scss';

class PrintFR extends Component {
    constructor(props){
        super(props);
        this.state = {
            qtyPack:''
        }
    }
countChange = (e) =>{
    this.setState({qtyPack:  e.target.value})
}



    render() {


        return (
            <div className="wyrob">
                <h2>PRODUIT FINAL</h2>
               <div className="wyrob-body">
                   <div className="wyrob-body_title">
                       <h4>NUMÉRO DE COMMANDE :</h4>
                       <textarea className="input-title" defaultValue={this.props.data[0].name}></textarea>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>TITRE DE LA COMMANDE :</h4>
                       <textarea className="input-title name" defaultValue={this.props.data[0].title} ></textarea>
                   </div>
                   <div className="wyrob-body_item">
                        <div className="item">
                           <h4>QUANTITÉ SUR LA PALETTE :</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]} ></textarea>
                        </div>
                        <div className="item">
                           <h4>NOMBRE DE COLIS :</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].count.match(/^\d+/)[0]/ this.state.qtyPack} ></textarea>
                        </div>
                        <div className="item">
                           <h4>QUANTITÉ DE PIÈCES DANS LE COLIS :</h4>
                           <textarea className="input-item" value={this.state.qtyPack} onChange={this.countChange} ></textarea>
                        </div>
                        <div className="item">
                           <h4>NUMÉRO DE PALETTE SUIVANTE :</h4>
                           <textarea className="input-item" defaultValue='1'></textarea>
                        </div>
                        <div className="item last-item">
                           <h4>NUMÉRO TOTAL DE PALETTES :</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].subcount} ></textarea>
                        </div>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>COMMENTAIRES :</h4>
                       <textarea className="input-title" defaultValue="VEOPRINT" ></textarea>
                   </div>
               </div>
            </div>
        );
    }
}

export default PrintFR;