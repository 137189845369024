import { Component} from 'react';


import './job-item.scss';



class JobItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter:[],
            activeButtonIndex: null, // Індекс активної кнопки
        }
    }
    
handleButtonClick = (index) => {
    this.setState({ activeButtonIndex: index });
    this.props.activeButtonIndex(index)
}

render() {

    const {data} = this.props;
    const uniqueObjects = {};
    // Пройдіться по масиву та додайте об'єкти до контейнера
    data.forEach((obj) => {
        const key = obj.id ; // Об'єднайте властивості, які мають бути унікальними
        uniqueObjects[key] = obj;
        });
    // Перетворіть контейнер знову в масив унікальних об'єктів
    const uniqueArray = Object.values(uniqueObjects);
    if(data[0].order === "wyslane"){uniqueArray.sort(( a,b) => a.id.localeCompare(b.id));}


    let element = uniqueArray.map((title, index) => {
        return (
        <li key={index}> <button
                     className={  index === this.props.activeIndex ? "active" : ""}
                     onClick={
                        () => {

                            if(this.props.editStatus){
                                alert('najpierw zapisz zmiany')
                            }else{  this.handleButtonClick(index);
                                    this.props.listItem(0) ;
                                    this.props.activeJobBtn ? this.props.upData(title.id) :  this.props.fetchDataSoldTitle(title.id, title.order) ;} ;
                        }
                     }
                        >{title.id}
                    </button></li>
        )
    });



    return (
            <>
            {element}
            </>
    )   
}

}
 export default JobItem;
